import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button, Card } from 'react-bootstrap'
import { breakpoints, colors } from 'globalStyles'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { parseISO, addDays } from 'date-fns'

const StyledShipmentDetailsCard = styled(Card)`
  margin-bottom: 24px;
  border-radius: 8px 0px;
  border: none !important;
  box-shadow: 0px 0px ${(props) => (props.shadow ? '8px' : '5px')} rgba(0, 0, 0, 0.2);
  .card-body {
    font-size: 14px;
    line-height: 24px;
    text-align: center;
  }
  .card-header {
    padding: 0px 12px;
    background-color: ${colors.translucent_light_blue};
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    border-radius: 8px 0 !important;
    @media ${breakpoints.tablet} {
      font-size: 16px;
      font-weight: 600;
    }
  }
  .iconStyle {
    margin-right: 12px;
    width: 20px;
    height: auto;
    @media ${breakpoints.tablet} {
      width: 24px;
    }
  }
  .headerRow {
    display: flex;
    width: 100%;
    height: 30px;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    margin-top: 0px;
  }
  .headerRowPinIndex {
    margin: inherit;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    @media ${breakpoints.phone} {
      font-size: 12px;
    }
  }
  .headerRowPin {
    margin: inherit;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    @media ${breakpoints.phone} {
      font-size: 14px;
    }
  }
  .headerRowLeadPin {
    margin-left: 5px;
    font-weight: 600;
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 24px;
    @media ${breakpoints.phone} {
      font-size: 14px;
    }
  }
  .btn-circle {
    width: 26px;
    height: 26px;
    padding: 3px 0px;
    border-radius: 15px;
    font-size: 12px;
    background-color: ${colors.primary_white};
    margin: 3px 0 0 auto;
    &.btn-check:focus + &.btn,
    &.btn:focus {
      box-shadow: 0 0 0 0.15rem ${colors.secondary_light_blue};
    }
  }
  .headerStatus {
    margin: inherit;
    margin-left: 12px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    @media ${breakpoints.phone} {
      font-size: 14px;
    }
  }
  .contentRow {
    display: flex;
    padding: 2px 19px;
    max-width: 335px;
    margin: auto;
    text-align: left;
  }
  .contentRowKey {
    display: inline-flex;
    width: 60%;
  }
  .contentRowValue {
    display: inline-flex;
    width: 40%;
  }
`

const PackageDetailsCard = ({
  shipmentIndex,
  pin,
  isLeadPin,
  toolTip,
  onDirect,
  status,
  statusIcon,
  weight,
  shipmentDate,
  totalPackages,
}) => {
  const { t } = useTranslation()
  // Temporary fix for the issue of the package details having wrong date
  // TODO: Change the date time based on destination time zone
  const originDate = parseISO(shipmentDate)
  const dateAddOne = addDays(originDate, 1)
  return (
    <StyledShipmentDetailsCard>
      <Card.Header>
        <div className="headerRow">
          <p className="headerRowPinIndex">{shipmentIndex}</p>
          <p className="headerRowPin">&#8201;&mdash; {pin}</p>
          {isLeadPin && (
            <div className="headerRowLeadPin">
              <span>- {t('packageDetails.lead_pin')}</span>
              {toolTip}
            </div>
          )}
          {totalPackages > 1 && (
            <Button
              aria-label={`${t('aria_labels.show_shipment_details')} ${pin}`}
              className="btn-circle"
              variant="light"
              id={pin}
              onClick={() => onDirect(pin)}
            >
              <FontAwesomeIcon icon={faArrowRight} size="xl" color={colors.secondary_light_blue} />
            </Button>
          )}
        </div>
        <div className="headerRow">
          {statusIcon}
          <p className="headerStatus">{status}</p>
        </div>
      </Card.Header>
      <Card.Body>
        <div className="contentRow">
          <span className="contentRowKey">{t('packageDetails.est_weight')}</span>
          <span className="contentRowValue">{weight}</span>
        </div>
        <div className="contentRow">
          <span className="contentRowKey">{t('packageDetails.shipment_date')}</span>
          <span className="contentRowValue">
            {t('date_short', {
              date: `${dateAddOne}`,
            })}
          </span>
        </div>
      </Card.Body>
    </StyledShipmentDetailsCard>
  )
}

PackageDetailsCard.propTypes = {
  shipmentIndex: PropTypes.string.isRequired,
  pin: PropTypes.string.isRequired,
  isLeadPin: PropTypes.bool.isRequired,
  toolTip: PropTypes.node,
  status: PropTypes.string.isRequired,
  statusIcon: PropTypes.node,
  weight: PropTypes.string.isRequired,
  shipmentDate: PropTypes.string.isRequired,
  onDirect: PropTypes.func,
  totalPackages: PropTypes.number.isRequired,
}

PackageDetailsCard.defaultProps = {
  toolTip: null,
  statusIcon: null,
  onDirect: null,
}

export default PackageDetailsCard
