import React from 'react'
import { useTranslation } from 'react-i18next'
import PopUp from 'reusableComponents/PopUp'
import signatureSVG from 'assets/svg/pen-to-square-black.svg'
import Button from 'react-bootstrap/Button'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { setShipmentPreferences } from 'store/shipping/shippingSlice'
import { selectDeliverySummary } from 'store/shipping/shippingSelectors'
import SignatureReleaseAuthorization from './SignatureReleaseAuthorization'
import SignatureReleaseRemoval from './SignatureReleaseRemoval'

const SignatureReleasePopUp = ({ show, onHide, popUpType }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { deliveryLocation, buzzerCode, additionalDeliveryInstructions } =
    useSelector(selectDeliverySummary)

  const handleClick = (signaturePreference, previousSignaturePref) => {
    dispatch(
      setShipmentPreferences(
        additionalDeliveryInstructions,
        buzzerCode,
        deliveryLocation,
        signaturePreference,
        previousSignaturePref
      )
    )
    onHide()
  }
  const modalType = {
    signAuth: {
      title: t('signaturePreferences.signAuth'),
      saveText: t('buttonActions.save'),
      cancelText: t('buttonActions.cancel'),
      component: <SignatureReleaseAuthorization handleClick={handleClick} />,
    },
    signRemoval: {
      title: t('signaturePreferences.signRemoval'),
      saveText: t('buttonActions.yes'),
      cancelText: t('buttonActions.no'),
      component: <SignatureReleaseRemoval handleClick={handleClick} />,
    },
  }

  const modalData = modalType[popUpType]

  return (
    <PopUp
      show={show}
      primaryButton={
        <Button variant="secondary" onClick={onHide}>
          {modalData.cancelText}
        </Button>
      }
      secondaryButton={
        <Button type="submit" variant="primary" form="signForm">
          {modalData.saveText}
        </Button>
      }
      icon={signatureSVG}
      title={modalData.title}
      onHide={onHide}
    >
      {modalData.component}
    </PopUp>
  )
}

SignatureReleasePopUp.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  popUpType: PropTypes.string.isRequired,
}

export default SignatureReleasePopUp
