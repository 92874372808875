/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import useDetectPhone from 'hooks/useDetectPhone'
import { verifyByToken } from 'api/shipping'
import { PropTypes } from 'prop-types'
import { colors } from 'globalStyles'
import Card from 'react-bootstrap/Card'

const verificationCardContent = (respCode, t) => {
  switch (respCode) {
    case 0:
      return t('verification_messages.loading')
    case 400:
      return t('verification_messages.failed_request')
    case 404:
      return t('verification_messages.failed_request')
    case 409:
      return t('verification_messages.already_verified')
    default:
      return 'N/A'
  }
}

const VerificationCard = ({ token }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [respCode, setRespCode] = useState(0)
  const bpLgUnder = useDetectPhone()

  const unsubMsgStyle = {
    width: '100%',
    position: 'relative',
    border: 'none',
    flex: '1',
    marginLeft: '0px',
  }

  const lgUnderUnsubMsgStyle = {
    position: 'relative',
    border: 'none',
    flex: '1',
    marginLeft: '0px',
    marginRight: '15px',
    alignSelf: 'start',
  }

  const cardTitle = {
    fontWeight: '600px',
    fontSize: '20px',
    color: `${colors.text_link}`,
    marginTop: '80px',
  }

  const cardBodyStyle = {
    width: '100%',
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const verifyResp = await verifyByToken(token)
        if (verifyResp.status === 200) {
          navigate(`/rs/tr/${verifyResp.data.language}/${verifyResp.data.shipmentHashedPin}`, {
            replace: true,
          })
        }
      } catch (error) {
        // handle error
        if (error.response.status === 401) {
          const shipmentPinVal = error.response.data.shipmentPin
          const expiredTokenVal = error.response.data.expiredToken
          navigate(
            `/rs/tr/${error.response.data.lang}/${error.response.data.shipmentHashedPin}/verifyLinkExpired`,
            {
              state: {
                shipmentPin: shipmentPinVal,
                expiredToken: expiredTokenVal,
              },
            }
          )
        }
        if (error.response && error.response.status) {
          setRespCode(error.response.status)
        }
      }
    }
    fetchData()
  }, [token, navigate])

  return (
    <Card style={bpLgUnder ? lgUnderUnsubMsgStyle : unsubMsgStyle}>
      <Card.Body style={cardBodyStyle}>
        <Card.Title style={cardTitle}>{verificationCardContent(respCode, t)}</Card.Title>
      </Card.Body>
    </Card>
  )
}

VerificationCard.propTypes = {
  token: PropTypes.string,
}

VerificationCard.defaultProps = {
  token: null,
}

export default VerificationCard
