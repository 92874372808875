import i18n from 'i18next'
import ReactDOMServer from 'react-dom/server'
import differenceInSeconds from 'date-fns/differenceInSeconds'

export const isLangSupported = () => {
  return i18n.options.supportedLngs.includes(i18n.language)
}

export const getSeason = () => {
  const todaysDate = new Date()
  const month = todaysDate.getMonth() + 1

  if (month >= 2 && month <= 4) {
    return 'spring'
  }
  if (month >= 5 && month <= 7) {
    return 'summer'
  }
  if (month >= 8 && month <= 10) {
    return 'fall'
  }
  return 'winter'
}

export const getVendor = (vendor) => {
  if (vendor === 'Canadian Tire') {
    return 'canadianTire'
  }
  if (vendor === 'Dell') {
    return 'dell'
  }
  return 'generic'
}

export const getDOMEncodeString = (dom) => {
  const strDOM = encodeURIComponent(ReactDOMServer.renderToString(dom))
  return `data:image/svg+xml,${strDOM}`
}

export const setCookieToLocalStorage = (hashedPin, sessionId, expires) => {
  try {
    const idKey = `${hashedPin}_id`
    const expireKey = `${hashedPin}_expires`
    window.localStorage.setItem(idKey, JSON.stringify(sessionId))
    window.localStorage.setItem(expireKey, JSON.stringify(expires))
  } catch (error) {
    console.error('helper function setCookie failed due to: ', error) // eslint-disable-line no-console
  }
}

export const getCookieIDFromLocalStorage = (hashedPin) => {
  try {
    const id = window.localStorage.getItem(`${hashedPin}_id`)
    return id ? JSON.parse(id) : null
  } catch (error) {
    console.error('helper function getCookieIDFromLocalStorage failed due to: ', error) // eslint-disable-line no-console
    return null
  }
}

export const getCookieExpireFromLocalStorage = (hashedPin) => {
  try {
    const expire = window.localStorage.getItem(`${hashedPin}_expires`)
    return expire ? JSON.parse(expire) : null
  } catch (error) {
    console.error('helper function getCookieExpireFromLocalStorage failed due to: ', error) // eslint-disable-line no-console
    return null
  }
}

export const removeCookieFromLocalStorage = (hashedPin) => {
  try {
    window.localStorage.removeItem(`${hashedPin}_id`)
    window.localStorage.removeItem(`${hashedPin}_expires`)
  } catch (error) {
    console.error('helper function removeCookieFromLocalStorage failed due to: ', error) // eslint-disable-line no-console
  }
}

export const getSessionValidity = (hashedPin) => {
  const expire = getCookieExpireFromLocalStorage(hashedPin) * 1000
  const now = Date.now()
  return now < expire
}

export const getDifferenceInSeconds = (laterDate, earlierDate) => {
  return differenceInSeconds(laterDate, earlierDate)
}

// Input: key/value to store, along with component that is calling the function
export const setValueToLocalStorage = (key, value, component) => {
  try {
    window.localStorage.setItem(`${component}_${key}`, value)
  } catch (error) {
    console.error('helper function setValueToLocalStorage failed due to: ', error) // eslint-disable-line no-console
  }
}

// Input: key for value to get, along with component that is calling the function
export const getValueFromLocalStorage = (key, component) => {
  try {
    return window.localStorage.getItem(`${component}_${key}`)
  } catch (error) {
    console.error('helper function getValueFromLocalStorage failed due to: ', error) // eslint-disable-line no-console
    return null
  }
}

// Input: key for value to remove, along with component that is calling the function
export const removeValueFromLocalStorage = (key, component) => {
  try {
    window.localStorage.removeItem(`${component}_${key}`)
  } catch (error) {
    console.error('helper function removeValueFromLocalStorage failed due to: ', error) // eslint-disable-line no-console
  }
}
