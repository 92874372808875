import React, { useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import 'webComponents/contactUs'
import 'webComponents/faqs'
import constants from 'constants.json'
import FaqJson from 'webComponents/contents/faqs.json'
import { useScrollToSection } from 'hooks/useScrollToSection'
import { selectIsTerminalPresent } from 'store/shipping/shippingSelectors'
import { useSelector } from 'react-redux'
import useDetectCansAndLocker from 'hooks/useDetectCansAndLocker'
import { Container, Section } from './SupportPage.style'

const SupportPage = () => {
  const faqCardRef = useRef(null)
  const contactUsCardRef = useRef(null)
  const location = useLocation()
  const faqs = JSON.stringify(FaqJson)
  const isTerminalPresent = useSelector(selectIsTerminalPresent)
  const {
    t,
    i18n: { language },
  } = useTranslation()

  useScrollToSection(faqCardRef, 'faqs', location)
  useScrollToSection('TOP', 'contactUs', location)
  const isCansAndLocker = useDetectCansAndLocker(location)
  const CONTACT_DETAILS_CHAT_BOT_LINK = constants.CONTACT_DETAILS.CHAT_BOT_LINK
  const CONTACT_DETAILS_TELEPHONE = constants.CONTACT_DETAILS.TELEPHONE
  const CONTACT_DETAILS_MAIL_WITH_SUBJECT = constants.CONTACT_DETAILS.MAIL_WITH_SUBJECT

  return (
    <Container>
      <Section ref={contactUsCardRef} tabIndex={-1}>
        <h1 className="heading">{t('header.contact_us')}</h1>
        <icon-card-container>
          <icon-card
            cardtype="Chat"
            cardtitle={t('contact_us.chat')}
            contactdetailsbottomline={t('contact_us.virtual_assistant')}
            href={CONTACT_DETAILS_CHAT_BOT_LINK}
          />

          <icon-card
            cardtype="PHONE"
            cardtitle={t('contact_us.call')}
            extradescription={t('contact_us.personal_support_service')}
            contactdetailsbottomline={`${CONTACT_DETAILS_TELEPHONE}`}
            href={`tel:+${CONTACT_DETAILS_TELEPHONE}`}
          />

          <icon-card
            cardtype="Email"
            cardtitle={t('contact_us.email_us')}
            contactdetails={t('contact_us.email')}
            extradescription={`(${t('contact_us.email_request_time')})`}
            hrefemail={`mailto:${CONTACT_DETAILS_MAIL_WITH_SUBJECT}`}
          />
        </icon-card-container>
      </Section>

      {isTerminalPresent && !isCansAndLocker && (
        <Section hashSegment ref={faqCardRef} tabIndex={-2}>
          <h1 className="heading">{t('header.faqs')}</h1>
          <purolator-faqs content={faqs} lang={language} />
        </Section>
      )}
    </Container>
  )
}

export default SupportPage
