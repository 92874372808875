/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { breakpoints, colors } from 'globalStyles'
import { unsubscribeByToken } from 'api/shipping'
import { PropTypes } from 'prop-types'
import { useMediaQuery } from 'react-responsive'
import Card from 'react-bootstrap/Card'
import { useSelector } from 'react-redux'
import { selectTrackShipmentUrl } from 'store/global/globalSelectors'
import {
  unsubMsgStyle,
  lgUnderUnsubMsgStyle,
  cardTitle,
  cardBodyStyle,
  cardText,
} from './unsubscriptionPageStyles'

function unsubscriptionCardContent(respCode, shipmentPin, t, trackShipmentURL) {
  switch (respCode) {
    case 200: {
      const successUnsubscribeMsg = {
        msg: t('unsubscribe_messages.successfully_unsubscribe', { pin: shipmentPin }),
      }
      const trackShipmentURLWithPin = trackShipmentURL + shipmentPin
      return (
        <>
          <Card.Text style={(cardText, { marginBlockEnd: '0px' })}>
            {successUnsubscribeMsg.msg}
          </Card.Text>
          <Card.Text style={(cardText, { marginBlockStart: '0px' })}>
            {t('unsubscribe_messages.track_shipment')}
            <a
              style={{ textDecoration: 'none', color: colors.primary_blue }}
              href={trackShipmentURLWithPin}
            >
              {t('unsubscribe_messages.click_here')}
            </a>
          </Card.Text>
        </>
      )
    }
    case 400 || 410:
      return (
        <Card.Text style={cardText}>
          {t('unsubscribe_messages.invalid_token_or_already_unsubscribed')}
        </Card.Text>
      )
    case 500:
      return <Card.Text style={cardText}>{t('unsubscribe_messages.service_unavailable')}</Card.Text>
    case 422: {
      const invalidSubscribeMsg = {
        msg: t('unsubscribe_messages.user_didnt_verify', { pin: shipmentPin }),
      }
      return <Card.Text style={cardText}>{invalidSubscribeMsg.msg}</Card.Text>
    }
    default:
      return 'N/A'
  }
}

const UnsubscriptionCard = ({ unsubscribeToken }) => {
  const { t } = useTranslation()
  const [respCode, setRespCode] = useState(null)
  const [shipmentPin, setShipmentPin] = useState(null)
  const trackShipmentURL = useSelector(selectTrackShipmentUrl)

  const bpLgUnder = useMediaQuery({
    query: breakpoints.lg_under,
  })

  useEffect(() => {
    unsubscribeByToken(unsubscribeToken)
      .then((res) => {
        if (res.status === 200) {
          setRespCode(200)
          setShipmentPin(res.data.shipmentPin)
        }
      })
      .catch((error) => {
        // handle error
        if (
          error.response &&
          (error.response.status === 400 ||
            error.response.status === 500 ||
            error.response.status === 410 ||
            error.response.status === 422)
        ) {
          setRespCode(error.response.status)
          if (error.response.status === 410 || error.response.status === 422) {
            setShipmentPin(error.response.data.shipmentPin)
          }
        }
      })
  }, [])

  return (
    <Card style={bpLgUnder ? lgUnderUnsubMsgStyle : unsubMsgStyle}>
      <Card.Body style={cardBodyStyle}>
        <Card.Title style={cardTitle}>{t('purolator_your_way')}</Card.Title>
        <div>{unsubscriptionCardContent(respCode, shipmentPin, t, trackShipmentURL)}</div>
      </Card.Body>
    </Card>
  )
}

UnsubscriptionCard.propTypes = {
  unsubscribeToken: PropTypes.string,
}

UnsubscriptionCard.defaultProps = {
  unsubscribeToken: null,
}

export default UnsubscriptionCard
