import styled from 'styled-components'
import { breakpoints } from 'globalStyles'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-bottom: 42px;
  @media ${breakpoints.xxs} {
    max-width: 319px;
  }
`
export const VerificationDetailsContainer = styled.div`
  position: relative;
  diplay: flex;
  justify-content: center;
  padding: 0px 16px;
`

export const VerificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 580px;
  gap: 24px;
`
export const VerificationInfoContainer = styled.div`
  display: grid;
  grid-gap: 30px;
  width: 100%;
  @media ${breakpoints.xxs} {
    max-width: 315px;
  }
`
