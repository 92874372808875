import React from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import useDetectPhone from 'hooks/useDetectPhone'
import styled from 'styled-components'
import { colors } from 'globalStyles'
import { Trans, useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/pro-light-svg-icons'
import { selectIsLoading } from 'store/shipping/shippingSelectors'
import { Button } from 'react-bootstrap'
import LoadingTruck from 'reusableComponents/LoadingTruck'
import { resendVerificationLink } from 'api/shipping'

import {
  Container,
  VerificationContainer,
  VerificationDetailsContainer,
  VerificationInfoContainer,
} from './expiredVerificationLink.styles'

const PYWLink = styled.a.attrs({
  id: 'pyw-link',
  rel: 'noreferrer',
})`
  color: ${colors.primary_blue};
`

const envelopeIconStyle = {
  color: `${colors.secondary_light_blue}`,
  width: 50,
  height: 37.5,
}

const StyledIcon = styled.div`
  width: 100%;
  text-align: center;
  }
`

const StyledTitle = styled.div`
  width: 100%;
  text-align: center;
  font-size: 26px;
  line-height: 35.41px;
  color: ${colors.primary_blue};
  }
`

const StyledContent = styled.div`
  width: 100%;
  text-align: center;
  }
`

const StyledButton = styled.div`
  width: 100%;
  text-align: center;
  gap: 10px;
  }
`

const buttonStyle = {
  width: 265,
  height: 50,
}

const StyledSubContent = styled.div`
  width: 100%;
  text-align: center;
  }
`

const getImagePath = ({ isMobile }) => {
  const device = isMobile ? 'mobile' : 'desktop'
  const imageName = `winter_outfordelivery_${device}.svg`
  return `${process.env.PUBLIC_URL}/assets/bannerImages/generic/${imageName}`
}

const ExpiredVerificationLink = () => {
  const { lang, pin } = useParams()
  const isMobile = useDetectPhone()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const path = `/rs/tr/${lang}/${pin}`

  const handleResend = () => {
    const body = {
      shipmentHashedPin: pin,
      shipmentPin: location.state.shipmentPin,
      language: lang,
      expiredToken: location.state.expiredToken,
    }
    // Bad design because of business decision
    resendVerificationLink(body)
    navigate(path, {
      replace: true,
    })
  }

  const isLoading = useSelector(selectIsLoading)

  if (isLoading) {
    return <LoadingTruck />
  }

  return (
    <Container>
      <img
        style={{ width: '100%' }}
        alt="Illustration of Purolator truck describing the status of delivery"
        src={getImagePath({ isMobile })}
      />
      <VerificationDetailsContainer>
        <VerificationContainer>
          <VerificationInfoContainer>
            <StyledIcon>
              <FontAwesomeIcon icon={faEnvelope} style={envelopeIconStyle} />
            </StyledIcon>
            <StyledTitle>{t('emailVerificationLinkExpired.link_expired')}</StyledTitle>
            <StyledContent>{t('emailVerificationLinkExpired.resend_link_content')}</StyledContent>
            <StyledButton>
              <Button variant="primary" onClick={handleResend} style={buttonStyle}>
                {t('emailVerificationLinkExpired.resend_button_content')}
              </Button>
            </StyledButton>
            <StyledSubContent>
              <Trans
                t={t}
                i18nKey="emailVerificationLinkExpired.re_enter_email_addr"
                components={[<PYWLink href={path} />]}
              />
            </StyledSubContent>
          </VerificationInfoContainer>
        </VerificationContainer>
      </VerificationDetailsContainer>
    </Container>
  )
}

export default ExpiredVerificationLink
