import { React, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { faBell } from '@fortawesome/pro-light-svg-icons'
import { faPencil } from '@fortawesome/free-solid-svg-icons'
import { breakpoints, colors } from 'globalStyles'
import { useTranslation, Trans } from 'react-i18next'
import { getDOMEncodeString } from 'utils/helperFunctions'
import styled from 'styled-components'
import { Card } from 'react-bootstrap'
import { sendVerificationEmail, unsubscribe, resendVerificationLink } from 'api/shipping'
import { selectEmailNotificationDetails } from 'store/shipping/shippingSelectors'
import InputField from 'reusableComponents/InputField/InputField'
import Button from 'react-bootstrap/Button'
import constants from 'constants.json'
import Form from 'react-bootstrap/Form'
import PopUp from 'reusableComponents/PopUp'
import Checkbox from 'reusableComponents/Checkbox'
import TermsAndConditions from 'components/TermsAndConditions'
import { setOptedInStatusDetails } from 'store/shipping/shippingSlice'
import { StyledSwitch, bellIconStyle } from './EmailToggle.style'

const StyledBaseCard = styled(Card)`
  display: flex;
  flex-direction: row !important;
  position: relative;
  padding: 8px 4px;
  border-radius: 8px 0px;
  min-height: 52px;
  width: 100%;
  background-color: ${colors.web_grey_light} !important;
  box-shadow: 0 0 8 0 rgba(0, 0, 0, 0.2);
  border-radius: 8px 0px;
  .card-body {
    display: block;
    background-color: ${colors.web_grey_light};
  }
  .iconStyle {
    display: flex;
    position: relative;
  }

  input#EmailInput.form-control {
    border: 1px solid ${colors.secondary_grey};
    background-image: none;
    box-shadow: none;
  }
`
const CenteredDiv = styled.div`
  display: flex;
  margin-top: 20px;
  @media ${breakpoints.phone} {
    justify-content: center;
  }
`
const StyledForm = styled(Form)`
  @media ${breakpoints.desktop} {
    margin-left: 30px;
  }
`

const StyledSpan = styled.span`
  font-size: 12px;
  color: ${colors.secondary_black};
  line-height: 16px;
  font-style: normal;
  font-weight: 400;
  display: table-cell;
  width: 90%;
  position: relative;
  vertical-align: top;
  padding: 7px 20px 0px 8.5px;
  @media ${breakpoints.desktop} {
    font-size: 14px;
    padding: 6.5px 0px 0px 5.5px;
  }
`

const SpanStyled = styled.span`
  font-weight: 600;
  display: table-cell;
  width: 90%;
  position: relative;
  vertical-align: top;
  padding: 7px 20px 0px 8.5px;
  @media ${breakpoints.phone} {
    font-size: 14px;
    padding: 6.5px 0px 0px 5.5px;
  }
`

const StyledDiv = styled.div`
  display: table-cell;
`
const ErrorText = styled.div`
  color: red;
  margin-top: 5px;
  font-size: 12px;
  display: inline;
`
const SignatureLinks = styled(Button).attrs({ variant: 'link' })`
  padding: 0;
  &.btn-check:focus + &.btn,
  &.btn:focus {
    box-shadow: 0 0 0 4px rgb(0 0 0 / 0%);
  }
  font-size: 16px;
  line-height: 24px;
  @media only screen and ${breakpoints.tablet} {
    font-size: 18px;
    line-height: 32px;
  }
  @media only screen and ${breakpoints.desktop} {
    font-size: 18px;
    line-height: 25px;
  `

const EmailToggle = () => {
  const { t, i18n } = useTranslation('translation')
  const { language } = i18n
  const dispatch = useDispatch()
  const emailNotificationDetails = useSelector(selectEmailNotificationDetails)
  let optedInForEmailFlag = false
  if (
    emailNotificationDetails.optedInForEmail === constants.OPTIN_STATUS.OPTED_IN_NOT_VERIFIED ||
    emailNotificationDetails.optedInForEmail === constants.OPTIN_STATUS.OPTED_IN_VERIFIED
  )
    optedInForEmailFlag = true
  else optedInForEmailFlag = false
  const [toggleOn, setToggleOn] = useState(optedInForEmailFlag)
  // create new selectors for pins,phonenumber
  const message = t('toggle_email_msg')
  const [validated, setValidated] = useState(false)
  const [linkText, setLinkText] = useState(t('send_again_msg'))
  const [userEmail, setUserEmail] = useState(emailNotificationDetails.emailAddress)
  const [isClicked, setIsClicked] = useState(false)
  const [submittedStatus, setSubmittedStatus] = useState(
    emailNotificationDetails.optedInForEmail !== constants.OPTIN_STATUS.OPTED_OUT
  )
  const [verificationStatus, setverificationStatus] = useState(
    emailNotificationDetails.optedInForEmail
  )
  // 0: empty, 1: invalid, 2: valid
  const [emailValidationStatus, setemailValidationStatus] = useState(0)
  const errorMessage = t('verify_email_prompt')
  const [showModal, setShowModal] = useState(false)
  const [isEditing, setIsEditing] = useState(false)

  const optedInStatusDetails = {
    data: {
      optedInForEmail: 0,
      emailAddress: '',
    },
  }

  const handleChange = async () => {
    if (toggleOn) {
      if (
        emailNotificationDetails.optedInForEmail === constants.OPTIN_STATUS.OPTED_IN_NOT_VERIFIED ||
        emailNotificationDetails.optedInForEmail === constants.OPTIN_STATUS.OPTED_IN_VERIFIED
      ) {
        try {
          unsubscribe(
            emailNotificationDetails.id,
            emailNotificationDetails.shipmentPin,
            emailNotificationDetails.hashedPin,
            emailNotificationDetails.phoneNumber
          )
        } catch (e) {
          console.log(
            `ERROR unsubscribing from email notifications for shipmentPin: ${emailNotificationDetails.shipmentPin}`,
            e
          )
        }
      }
      dispatch(setOptedInStatusDetails(optedInStatusDetails))
      setUserEmail('')
      setverificationStatus(0)
      setSubmittedStatus(0)
      setemailValidationStatus(0)
      setIsEditing(false)
      setValidated(false)
    }
    setToggleOn(!toggleOn)
  }

  const cancelModal = () => {
    setShowModal(false)
    setSubmittedStatus(true)
    // TODO: check if email is verified?
    // setting this to unverified for now
    setverificationStatus(constants.OPTIN_STATUS.OPTED_IN_NOT_VERIFIED)
  }
  const validateEmail = (paramemail) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return re.test(String(paramemail).toLowerCase())
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    const form = event.currentTarget
    const isEmailValidated = validateEmail(userEmail)
    if (form.checkValidity() === false) {
      if (!isEmailValidated) {
        setemailValidationStatus(1)
      } else setemailValidationStatus(2)
      event.stopPropagation()
      setValidated(true)
      return
    }
    if (isEmailValidated) {
      setemailValidationStatus(2)
      setShowModal(true)
      try {
        await sendVerificationEmail(
          userEmail,
          language,
          emailNotificationDetails.shipmentPin,
          emailNotificationDetails.hashedPin
        )
        optedInStatusDetails.data.optedInForEmail = constants.OPTIN_STATUS.OPTED_IN_NOT_VERIFIED
        optedInStatusDetails.data.emailAddress = userEmail
        dispatch(setOptedInStatusDetails(optedInStatusDetails))
      } catch (e) {
        console.log('ERROR sending verification email:', e)
      }
    } else {
      setemailValidationStatus(1)
    }
  }

  const emailChangeHandler = (event) => {
    const email = event.target.value
    setUserEmail(email)
  }
  const resetEmail = () => {
    // handleSubmittedStatus()
    setSubmittedStatus(false)
    setIsEditing(true)
    // setErrorMessage(t(''))
  }
  const resendEmail = async () => {
    setLinkText(t('link_sent'))
    setIsClicked(true)
    try {
      const body = {
        shipmentHashedPin: emailNotificationDetails.hashedPin,
        shipmentPin: emailNotificationDetails.shipmentPin,
        language,
      }
      resendVerificationLink(body)
    } catch (e) {
      console.log('ERROR sending verification email:', e)
    }
    setTimeout(() => {
      setLinkText(t('send_again_msg'))
      setIsClicked(false)
    }, 4000) // The timeout for the send again cooldown
  }

  // const handleSendURL = (event) => {
  //   event.preventDefault()
  //   resendEmail()
  // }

  return (
    <StyledBaseCard>
      <Card.Body>
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <FontAwesomeIcon icon={faBell} size="lg" style={bellIconStyle} />

          <SpanStyled>{message}</SpanStyled>
          <StyledDiv>
            <StyledSwitch
              language={language}
              checked={toggleOn}
              disableRipple
              onChange={handleChange}
            />
          </StyledDiv>
        </div>
        {toggleOn && !submittedStatus ? (
          <div style={{ marginTop: '20px' }}>
            <StyledForm id="emailForm" noValidate validated={validated} onSubmit={handleSubmit}>
              <Form.Group controlId="emailAuth">
                <InputField
                  value={userEmail}
                  label={t('email_textbox')}
                  onChange={emailChangeHandler}
                  id="EmailInput"
                  isInvalid={emailValidationStatus === 1}
                  isValid={emailValidationStatus === 2}
                  errorText={t('invalid_email_msg')}
                />
                <Checkbox
                  isRequired
                  name="emailAuth"
                  label={
                    <Trans
                      t={t}
                      i18nKey="email_consent"
                      components={[
                        <a
                          href={
                            i18n.language === 'en'
                              ? process.env.REACT_APP_PUROLATOR_PRIVACY_POLICY_URL
                              : process.env.REACT_APP_PUROLATOR_PRIVACY_POLICY_URL_FR
                          }
                          target="_blank"
                          rel="noreferrer"
                          aria-label={t('privacy_policy_label')}
                        >
                          {t('privacy_policy_label')}
                        </a>,
                        <TermsAndConditions />,
                      ]}
                    />
                  }
                  errText={t('checkbox_error')}
                />
              </Form.Group>
              <CenteredDiv>
                <Button type="Submit" form="emailForm">
                  {isEditing ? t('buttonActions.update') : t('buttonActions.submit')}
                </Button>
              </CenteredDiv>
            </StyledForm>
            <PopUp
              show={showModal}
              secondaryButton={
                <Button type="submit" variant="primary" onClick={cancelModal}>
                  {t(`buttonActions.close`)}
                </Button>
              }
              icon={getDOMEncodeString(<FontAwesomeIcon icon={faEnvelope} />)}
              title={t(`verification_title`)}
              onHide={cancelModal}
            >
              <p>{t(`verification_msg`, { email: userEmail })}</p>
              <p>
                {t(`no_email_msg`)}
                {isClicked ? (
                  <span style={{ fontWeight: 'bold' }}>{linkText}</span>
                ) : (
                  <SignatureLinks onClick={resendEmail}>{linkText}</SignatureLinks>
                )}
              </p>
            </PopUp>
          </div>
        ) : (
          toggleOn &&
          verificationStatus !== constants.OPTIN_STATUS.OPTED_OUT && (
            <div
              style={{ display: 'flex', alignItems: 'center', width: '100%', marginTop: '20px' }}
            >
              <FontAwesomeIcon
                icon={faPencil}
                size="lg"
                color={colors.primary_blue}
                style={{ padding: '5px 0px 0px 3.5px' }}
                onClick={resetEmail}
              />

              <StyledSpan>
                <StyledSpan
                  onClick={resetEmail}
                  style={{
                    textDecoration: 'underline',
                    color: colors.secondary_blue,
                    fontSize: '14px',
                    paddingTop: '0px',
                  }}
                >
                  {userEmail}
                </StyledSpan>
                {verificationStatus === constants.OPTIN_STATUS.OPTED_IN_NOT_VERIFIED && (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ErrorText
                      style={{
                        textDecoration: 'plain',
                        paddingRight: '10px',
                        paddingLeft: '5px',
                      }}
                    >
                      {errorMessage}
                    </ErrorText>
                    {isClicked ? (
                      <span style={{ fontWeight: 'bold' }}>{linkText}</span>
                    ) : (
                      <SignatureLinks onClick={resendEmail}>{linkText}</SignatureLinks>
                    )}
                  </div>
                )}
              </StyledSpan>
            </div>
          )
        )}
      </Card.Body>
    </StyledBaseCard>
  )
}

export default EmailToggle
