import { colors } from 'globalStyles'

export const navLinkNoHover = {
  fontFamily: 'Open Sans',
  textDecoration: 'none',
  fontSize: '16px',
  color: colors.web_text_black,
  paddingTop: '6px',
  fontWeight: '600px',
}

export const navLinkHover = {
  fontFamily: 'Open Sans',
  textDecoration: 'none',
  fontSize: '16px',
  color: colors.web_text_black,
  paddingTop: '6px',
  fontWeight: '600px',
  borderBottom: `5px solid ${colors.secondary_light_blue}`,
}

export const navLinkNoHoverSub = {
  textDecoration: 'none',
  fontSize: '12px',
  color: colors.primary_blue,
  paddingTop: '7px',
}

export const navLinkHoverSub = {
  textDecoration: 'none',
  fontSize: '12px',
  color: colors.web_accessibility_min_grey,
  paddingTop: '7px',
}

export const cardText = {
  margin: '0px',
  paddingTop: '12px',
  paddingBottom: '12px',
  fontSize: '18px',
  fontWeight: '400px',
}

export const headerStyle = {
  width: '100%',
  height: '62px',
  display: 'flex',
  flexDirection: 'row',
  boxShadow: '0px 1px 8px rgba(0, 0, 0, 0.15)',
  transition: 'box-shadow 250ms',
  alignItems: 'center',
  padding: '0px 24px',
  justifyContent: 'space-between',
  backgroundColor: 'white',
  position: 'fixed',
  zIndex: '11',
}

export const lgUnderHeaderStyle = {
  width: '100%',
  height: '52px',
  display: 'flex',
  flexDirection: 'row',
  boxShadow: '0px 1px 8px rgba(0, 0, 0, 0.15)',
  transition: 'box-shadow 250ms',
  alignItems: 'center',
  padding: '0px 16px',
  justifyContent: 'space-between',
  backgroundColor: 'white',
  position: 'fixed',
  zIndex: '11',
}

export const unsubMsgStyle = {
  width: '100%',
  paddingTop: '30px',
  position: 'relative',
  border: 'none',
  flex: '1',
  marginLeft: '0px',
}

export const lgUnderUnsubMsgStyle = {
  paddingTop: '30px',
  position: 'relative',
  border: 'none',
  flex: '1',
  marginLeft: '0px',
  marginRight: '15px',
  alignSelf: 'start',
}

export const cardTitle = {
  fontWeight: '600px',
  fontSize: '20px',
  color: `${colors.text_link}`,
}

export const cardBodyStyle = {
  width: '100%',
}

export const footerStyle = {
  width: '100%',
  position: 'fixed',
  backgroundColor: `${colors.primary_blue}`,
  padding: '16px 24px',
  color: `${colors.web_text_light}`,
  display: 'flow-root',
  bottom: '0px',
  minHeight: '124px',
  height: '114px',
  textAlign: 'right',
}

export const lgUnderFooterStyle = {
  width: '100%',
  position: 'relative',
  backgroundColor: `${colors.primary_blue}`,
  padding: '16px 24px',
  color: `${colors.web_text_light}`,
  display: 'flow-root',
  bottom: '0px',
  height: '174.86px',
  minHeight: '124px',
  textAlign: 'right',
}

export const hoverStyle = {
  color: `${colors.web_text_light}`,
  textDecoration: 'underline',
}

export const noHoverStyle = {
  color: `${colors.web_text_light}`,
  textDecoration: 'none',
}
export const anchorStyle = {
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '12px',
  lineHeight: '16px',
  textDecoration: 'none',
  color: `${colors.web_text_light}`,
}

export const separatorStyle = {
  borderRight: '1px solid white',
  padding: '0px 6px',
}

export const noSeparatorStyle = {
  borderRight: 'none',
}
