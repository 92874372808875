/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import styled from 'styled-components'
import Button from 'react-bootstrap/Button'

import constants from 'constants.json'
import {
  selectEstimatedDeliveryDate,
  selectDeliveryDateTitleTranslationsKey,
  selectSignatureInfo,
  selectAgentTransferFlag,
  selectExpressChequeFlags,
  selectInterceptFlags,
  selectVendor,
  selectIsTerminalPresent,
  selectShipmentId,
  selectIsUserVerified,
  selectIsLeadPinDelivered,
  selectAllowFlagsForPreferences,
} from 'store/shipping/shippingSelectors'
import { breakpoints, colors } from 'globalStyles'
import { DetailCard, Row } from 'reusableComponents/Cards'
import { faCircleDollar, faPenToSquare, faCalendarDays } from '@fortawesome/pro-regular-svg-icons'
import { faCircleCheck, faClockFive } from '@fortawesome/pro-solid-svg-icons'
import ASRSVG from 'assets/svg/idIcon.svg'
import Tooltip from 'reusableComponents/ToolTip'
// import { ActionItemStyle } from './DeliveryDate.style'
import SignaturePreference from 'components/SignaturePreference'
import Verification from 'components/Verification'
import { parseISO } from 'date-fns'

const SignatureLinks = styled(Button).attrs({ variant: 'link' })`
  padding: 0;
  &.btn-check:focus + &.btn,
  &.btn:focus {
    box-shadow: 0 0 0 4px rgb(0 0 0 / 0%);
  }
  font-size: 16px;
  line-height: 24px;
  @media only screen and ${breakpoints.tablet} {
    font-size: 18px;
    line-height: 32px;
  }
  @media only screen and ${breakpoints.desktop} {
    font-size: 18px;
    line-height: 25px;
  }
`

// FIX NEEDED: Need to verify with api to understand all potential date formats
const formatDateTime = (dateTime, t) => {
  const splitDateTime = dateTime.split(' ')
  if (splitDateTime.length === 2 && splitDateTime[1]) {
    return t('delivery_date_time', { date: dateTime })
  }
  if (splitDateTime.length === 3) {
    return dateTime
  }
  if (splitDateTime.length === 4) {
    return t('tracking.estimated_delivery_evening', { date: dateTime })
  }
  // Temporary fix for the issue of the package details having wrong date
  // TODO: Change the date time based on destination time zone
  const originDate = parseISO(dateTime)
  return t('date', { date: originDate })
}

const formatEstimatedDeliveryDate = (deliveryDate, t) => {
  const { DELIVERY_DATE_RECALCULATING } = constants
  const { BUSINESS_DELIVERY_DATE } = constants
  if (!deliveryDate || deliveryDate === DELIVERY_DATE_RECALCULATING) {
    return (
      <Trans
        t={t}
        i18nKey="tracking.not_available"
        components={[<Tooltip id="tootip-edd-not-available" placement="bottom" />]}
      />
    )
  }
  if (deliveryDate.includes(BUSINESS_DELIVERY_DATE)) {
    return deliveryDate.replace(BUSINESS_DELIVERY_DATE, t('tracking.business_days'))
  }
  return formatDateTime(deliveryDate, t)
}

const commonIconSize = {
  phone: { size: '20px' },
  tablet: { size: '24px' },
  desktop: { size: '24px' },
}

const Centered = styled.div`
  text-align: left;
  font-size: 14px !important;
`

const DeliveryDate = () => {
  const { t } = useTranslation()
  const isLeadPinDelivered = useSelector(selectIsLeadPinDelivered)
  const estimateDeliveryDate = useSelector(selectEstimatedDeliveryDate)
  const titleTranslationKey = useSelector(selectDeliveryDateTitleTranslationsKey)
  const { signature, allowWaiveSignature } = useSelector(selectSignatureInfo)
  const agentTransferFlag = useSelector(selectAgentTransferFlag)
  const { interceptFlag, interceptReason } = useSelector(selectInterceptFlags)
  const id = useSelector(selectShipmentId)

  const { expressChequeFlag, expressChequeMOP, expressChequeAmount } =
    useSelector(selectExpressChequeFlags)
  const vendorName = useSelector(selectVendor)
  const displayEstimateDeliveryDate = formatEstimatedDeliveryDate(estimateDeliveryDate, t)
  const isTerminalPresent = useSelector(selectIsTerminalPresent)
  const isVerifiedUser = useSelector(selectIsUserVerified)
  const [showSigPreference, setShowSigPreference] = useState(false)
  const [popUpType, setPopUpType] = useState('signAuth')
  const [showVerificationPopUp, setShowVerificationPopUp] = useState(false)
  const { hasHFPUCompleted, hasReachedRetailLocation } = useSelector(selectAllowFlagsForPreferences)

  const onLinkClick = (type) => {
    if (isVerifiedUser) {
      setShowSigPreference(true)
      setPopUpType(type)
    } else {
      setShowVerificationPopUp(true)
    }
  }

  const getSignatureRequirements = () => {
    const SIGNATURE_REQUIRED = constants.SIGNATURE.REQUIRED
    const SIGNATURE_WAIVED = constants.SIGNATURE.WAIVED
    const SIGNATURE_ADULT = constants.SIGNATURE.ADULT

    if (signature === SIGNATURE_REQUIRED) {
      return !allowWaiveSignature ? (
        <Row
          faIcon={{
            icon: faPenToSquare,
            respSize: commonIconSize,
          }}
        >
          {t('deliveryPreferencesSummary.no_sign_waive')}
        </Row>
      ) : (
        <Row
          faIcon={{
            icon: faPenToSquare,
            respSize: commonIconSize,
          }}
        >
          <SignatureLinks onClick={() => onLinkClick('signAuth')} disabled={isLeadPinDelivered}>
            {t('deliveryPreferencesSummary.sign_required')}
          </SignatureLinks>
        </Row>
      )
    }

    if (signature === SIGNATURE_WAIVED) {
      return (
        <Row
          faIcon={{
            icon: faCircleCheck,
            color: colors.secondary_green,
            respSize: commonIconSize,
          }}
        >
          <SignatureLinks onClick={() => onLinkClick('signRemoval')} disabled={isLeadPinDelivered}>
            {t('deliveryPreferencesSummary.sign_waived')}
          </SignatureLinks>
        </Row>
      )
    }

    if (signature === SIGNATURE_ADULT) {
      return (
        <Row
          flex
          customIcon={{
            icon: ASRSVG,
            respSize: {
              phone: { size: '21px' },
              tablet: { size: '24px' },
              desktop: { size: '25px' },
            },
          }}
        >
          <Trans
            t={t}
            i18nKey="deliveryPreferencesSummary.adult_required"
            components={[<Tooltip id="tooltip-asr" placement="bottom" />]}
          />
        </Row>
      )
    }
    return null
  }
  const { RETURN_TO_SENDER } = constants
  const interceptText =
    interceptReason === RETURN_TO_SENDER
      ? t('interception_message2', { shipperName: vendorName })
      : t('interception_message1')
  const warningText = () => {
    if (interceptFlag) {
      return interceptText
    }
    if (agentTransferFlag) {
      return t('agentTransfer')
    }
    return null
  }

  const formatOfPayment = expressChequeFlag ? expressChequeMOP.replaceAll(/ /g, '_') : null
  const expressChequeText =
    expressChequeFlag && !isLeadPinDelivered
      ? t('expressCheque.express_cheque_text', {
          ecMOP: t(`expressCheque.${formatOfPayment}`),
          ecAmount: expressChequeAmount,
        })
      : null

  return (
    <>
      <DetailCard
        attentionTxt={warningText()}
        title={{
          name: t(titleTranslationKey),
          subTitle: displayEstimateDeliveryDate,
          faIcon: { icon: faCalendarDays },
        }}
        disabled={false}
      >
        {isTerminalPresent && getSignatureRequirements(signature, allowWaiveSignature, t)}
        {hasHFPUCompleted && !hasReachedRetailLocation && (
          <Row
            faIcon={{
              icon: faClockFive,
              respSize: commonIconSize,
            }}
          >
            {t('tracking.when_hfpu_completed')}
          </Row>
        )}

        {hasReachedRetailLocation && !isLeadPinDelivered && (
          <Row
            faIcon={{
              icon: faClockFive,
              respSize: commonIconSize,
            }}
          >
            {t('tracking.when_retail_location')}
          </Row>
        )}

        {expressChequeText && (
          <Row
            faIcon={{
              icon: faCircleDollar,
              respSize: {
                phone: { size: '20px' },
                tablet: { size: '24px' },
                desktop: { size: '24px' },
              },
            }}
          >
            {expressChequeText}
          </Row>
        )}
      </DetailCard>
      <Verification show={showVerificationPopUp} onHide={setShowVerificationPopUp} />
      <SignaturePreference
        show={showSigPreference}
        onHide={() => {
          setShowSigPreference(false)
        }}
        popUpType={popUpType}
      />
    </>
  )
}

export default DeliveryDate
