import React from 'react'
import PropTypes from 'prop-types'

import useDetectPhone from 'hooks/useDetectPhone'
import { getSeason } from 'utils/helperFunctions'
import constants from 'constants.json'

const getImagePath = ({ isMobile, status }) => {
  const device = isMobile ? 'mobile' : 'desktop'
  const STATUS_DELAYED = constants.STATUS.DELAYED
  const imageName =
    status === STATUS_DELAYED
      ? `${status}_${device}.svg`
      : `${getSeason()}_${status.replaceAll('_', '')}_${device}.svg`

  return `${process.env.PUBLIC_URL}/assets/bannerImages/generic/${imageName}`
}

const ImageBanner = ({ shipmentStatus }) => {
  const isMobile = useDetectPhone()

  return (
    <img
      style={{ width: '100%' }}
      alt="Illustration of Purolator truck describing the status of delivery"
      src={getImagePath({ isMobile, status: shipmentStatus })}
    />
  )
}

ImageBanner.propTypes = {
  shipmentStatus: PropTypes.string.isRequired,
}

export default ImageBanner
