import { colors } from 'globalStyles'
import Switch from '@mui/material/Switch'
import { styled } from '@mui/material/styles'

export const StyledSwitch = styled(Switch)(() => ({
  width: 50,
  height: 28,
  padding: 0,
  borderRadius: '50px',
  '& .MuiSwitch-switchBase': {
    padding: '3px 3px 0px 3px',
    '&.Mui-checked': {
      transform: 'translateX(22px)',
      color: `${colors.primary_white}`,
      '& + .MuiSwitch-track': {
        backgroundColor: `${colors.primary_green}`,
        opacity: 1,
        borderRadius: '50px',
        border: `${colors.primary_green}`,
      },
      '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
        background: `${colors.primary_white}`,
        boxShadow: 'none !important',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
    background: `${colors.primary_white}`,
    boxShadow: 'none !important',
  },
  '& .MuiSwitch-track': {
    backgroundColor: `${colors.web_grey}`,
    opacity: 1,
    borderRadius: '50px',
    border: `1px solid ${colors.web_grey}`,
  },
}))

export const bellIconStyle = {
  padding: '5px 0px 0px 3.5px',
  color: `${colors.primary_blue}`,
  width: 24,
  height: 24,
}
