import axios from 'axios'

const baseURL = process.env.REACT_APP_PYW_API
const pywBackendURL = process.env.REACT_APP_PYW_BACKEND_API

const api = axios.create({
  baseURL,
  timeout: 60 * 5 * 1000,
})

export const get = ({ path, config }) => {
  return api.get(path, config)
}

export const post = ({ path, body, config }) => {
  return api.post(path, body, config)
}

const pywBackendApi = axios.create({
  baseURL: pywBackendURL,
  timeout: 60 * 5 * 1000,
})

export const getPYWBackend = ({ path, config }) => {
  return pywBackendApi.get(path, config)
}

export const postPYWBackend = ({ path, body, config }) => {
  return pywBackendApi.post(path, body, config)
}

export const deletePYWBackend = ({ path, config }) => {
  return pywBackendApi.delete(path, config)
}
