import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectPackageDetails } from 'store/shipping/shippingSelectors'
import { CollectionCard } from 'reusableComponents/Cards'
import styled from 'styled-components'
import { breakpoints, colors } from 'globalStyles'

import PackageDetailsSVG from 'assets/svg/package_details_ellipse.svg'
import ShowMoreModal from 'reusableComponents/ShowMoreModal'
import Button from 'react-bootstrap/Button'
import FAIcon from 'reusableComponents/FAIcon'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { faTruckFast, faCircleCheck, faPersonCarryBox } from '@fortawesome/pro-regular-svg-icons'
import PackageDetailsCard from 'components/PackageDetailsCard'
import ToolTip from 'reusableComponents/ToolTip'
import PopUp from 'reusableComponents/PopUp'

const StyledDisplayContent = styled.div`
  display: flex;
  flex-direction: row
  justify-content: left;
  margin-right: 5px;
  .addressStyle {
    font-size: 14px;
    line-height: 28px;
    display: inline-flex;
    max-width: 150px;
    word-break: break-all;

    @media only screen and ${breakpoints.sm_above} {
      font-size: 16px;
    }
  }
  .addressIconStyle {
    text-align: center;
    display: inline-flex;
    flex-grow: 100;
    margin: 0 5px;
  }
  .addressLine {
    margin: 18px 2px 0px 2px;
    vertical-align: bottom;
    width: 45%;
    display: inline-block;
    border-top: 2px solid ${colors.primary_blue};
    line-height: 0;
  }
`

const StyledLinkButton = styled(Button)`
  margin: 0 0 15px;
  &.btn-check:focus + &.btn,
  &.btn:focus {
    box-shadow: 0 0 0 0.25rem rgb(0 0 0 / 0%);
  }
`
const statusRespSize = {
  phone: { size: '20px' },
  tablet: { size: '20px' },
  desktop: { size: '19px' },
}

const statusIconMapping = {
  1: <FAIcon icon={faPersonCarryBox} color={colors.primary_blue} respSize={statusRespSize} />,
  2: <FAIcon icon={faTruckFast} color={colors.primary_blue} respSize={statusRespSize} />,
  3: <FAIcon icon={faCircleCheck} color={colors.green_blue_bg} respSize={statusRespSize} />,
  4: <FAIcon icon={faTriangleExclamation} color={colors.warning_red} respSize={statusRespSize} />,
}

const ShipmentDetails = ({ onSelectPinHandler }) => {
  const { t } = useTranslation()
  const packageDetails = useSelector(selectPackageDetails)
  const [showMoreModal, setShowMoreModal] = useState(false)
  const [showPopUp, setShowPopUp] = useState(false)
  const packageDetailsLimit = 2
  let leadReferences = []

  const packageDirectHandler = (selectedPin) => {
    onSelectPinHandler(selectedPin)
    setShowMoreModal(false)
  }
  const packageDetailItems =
    packageDetails.length > 0
      ? packageDetails.map((packageItem, index) => {
          if (packageItem.isLeadPin) leadReferences = packageItem.references
          return (
            <PackageDetailsCard
              key={packageItem.packagepin}
              shipmentIndex={t('packageDetails.shipment_index', {
                index: index + 1,
                totalPackages: packageDetails.length,
              })}
              pin={packageItem.packagepin}
              isLeadPin={packageItem.isLeadPin}
              toolTip={
                <ToolTip id="tooltip-shipment-details-lead-pin" placement="top">
                  {t('packageDetails.tooltip_content')}
                </ToolTip>
              }
              status={
                packageItem.isMissedDelivery
                  ? t('packageDetails.missed_delivery')
                  : packageItem.status
              }
              onDirect={packageDirectHandler}
              statusIcon={statusIconMapping[packageItem.statusCode]}
              weight={packageItem.packageWeight}
              shipmentDate={packageItem.shipmentDate}
              totalPackages={packageDetails.length}
            />
          )
        })
      : []

  const referenceIDHandler = () => {
    setShowPopUp(true)
  }

  const referenceIdstyle = {
    margin: '0 auto 29px 14.76px',
  }

  const referenceIDButton = (
    <StyledLinkButton
      style={referenceIdstyle}
      id="referenceIDBtn"
      variant="link"
      onClick={referenceIDHandler}
      disabled={leadReferences.length === 0}
    >
      {t('packageDetails.reference')}
    </StyledLinkButton>
  )

  const showMoreHandler = () => {
    setShowMoreModal(true)
  }

  const onActionCloseHandler = () => {
    setShowPopUp((prev) => !prev)
  }

  const firstPackage =
    packageDetails.length > 0 ? packageDetails[0] : { service: t('not_available') }
  const firstCharUpperConverter = (input) =>
    input.charAt(0).toUpperCase() + input.slice(1).toLowerCase()

  const fromToTransit =
    packageDetails.length > 0 ? (
      <StyledDisplayContent>
        <span className="addressStyle">
          {`${firstCharUpperConverter(firstPackage.fromAddress.city)}, ${
            firstPackage.fromAddress.province
          }`}
        </span>
        <div className="addressIconStyle">
          <span className="addressLine">&nbsp;</span>
          <div>
            <FAIcon
              icon={faTruckFast}
              color={colors.primary_blue}
              respSize={{
                phone: { size: '16px' },
                tablet: { size: '24px' },
                desktop: { size: '16px' },
              }}
              style={{ verticalAlign: 'bottom' }}
            />
          </div>
          <span className="addressLine">&nbsp;</span>
        </div>
        <span className="addressStyle">
          {`${firstCharUpperConverter(firstPackage.toAddress.city)}, ${
            firstPackage.toAddress.province
          }`}
        </span>
      </StyledDisplayContent>
    ) : (
      t('cansDoorKnocker.service_unavailable')
    )

  return (
    <CollectionCard
      titleIconPath={PackageDetailsSVG}
      title={t('packageDetails.shipment_details_title')}
      subtitle={t('packageDetails.shipment_details', {
        numPackages: packageDetails.length,
        service: firstPackage.service,
      })}
      displayContent={fromToTransit}
      actionButton={referenceIDButton}
      collectionArray={packageDetailItems.slice(0, packageDetailsLimit)}
    >
      <div style={{ textAlign: 'center' }}>
        <PopUp
          show={showPopUp}
          primaryButton={
            <Button variant="primary" onClick={onActionCloseHandler}>
              {t('buttonActions.close')}
            </Button>
          }
          icon={PackageDetailsSVG}
          title={t('packageDetails.reference')}
          size="md"
        >
          <div style={{ display: 'inline-block', textAlign: 'left' }}>
            <url>
              {leadReferences.map((ref, i) => (
                <li key={`${ref}-${i}`}>{ref}</li>
              ))}
            </url>
          </div>
        </PopUp>
        <ShowMoreModal
          headerTitle={t('packageDetails.shipment_details_title')}
          iconPath={PackageDetailsSVG}
          show={showMoreModal}
          size="md"
          onHide={setShowMoreModal}
        >
          {packageDetailItems}
        </ShowMoreModal>
        {packageDetailItems.length > packageDetailsLimit && (
          <StyledLinkButton id="showMoreBtn" variant="link" onClick={showMoreHandler}>
            {t('packageDetails.see_more')}
          </StyledLinkButton>
        )}
      </div>
    </CollectionCard>
  )
}

ShipmentDetails.propTypes = {
  onSelectPinHandler: PropTypes.func.isRequired,
}

export default ShipmentDetails
