import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Card } from 'react-bootstrap'
import { breakpoints, colors } from 'globalStyles'

const StyledBaseCard = styled(Card)`
  display: flex;
  flex-direction: row !important;
  padding: 4px 8px;
  border-radius: 8px 0px;
  min-height: 69px;
  box-shadow: 0px 0px ${(props) => (props.shadow ? '8px' : '5px')} rgba(0, 0, 0, 0.2);
  .card-body {
    display: block;
    padding: 8px;
  }
  .card-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 10px;
    color: ${colors.primary_blue};
    @media ${breakpoints.desktop}, ${breakpoints.lg} {
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 10px;
    }
  }
  .iconStyle {
    margin-right: 4px;
    width: 20px;
    height: auto;
    @media ${breakpoints.tablet} {
      width: 24px;
    }
  }
`

const companyNameStyle = {
  marginTop: '19px',
  display: 'flex',
  alignItems: 'center',
  padding: '8px',
}

const BaseCard = ({ children, iconPath, title, companyLogo }) => {
  return (
    <StyledBaseCard>
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <div style={{ display: 'table' }}>
          <div style={{ display: 'table-cell' }}>
            <img src={iconPath} alt={`${title} Icon`} className="iconStyle" />
          </div>
          <div style={{ display: 'table-cell' }}>{children}</div>
        </div>
      </Card.Body>
      {companyLogo !== null && <div style={companyNameStyle}>{companyLogo}</div>}
    </StyledBaseCard>
  )
}

BaseCard.propTypes = {
  children: PropTypes.node.isRequired,
  iconPath: PropTypes.string.isRequired,
  title: PropTypes.string,
  companyLogo: PropTypes.node,
}

BaseCard.defaultProps = {
  companyLogo: null,
  title: '',
}

export default BaseCard
