/* eslint-disable camelcase */
import { createSelector } from '@reduxjs/toolkit'
import _ from 'lodash'

const selectShipping = (state) => state.shipping

export const selectShippingInfo = createSelector(selectShipping, (shipping) => shipping.info)
export const selectIsUserVerified = createSelector(
  selectShipping,
  (shipping) => shipping.isVerifiedUser
)
export const selectAllowRenewSession = createSelector(
  selectShipping,
  (shipping) => shipping.allowRenewSession
)

export const selectActivePinDetails = createSelector(
  selectShipping,
  (shipping) => shipping.selectedPackagePinInfo
)

export const selectIsLoading = createSelector(selectShipping, (shipping) => shipping.loading_info)

export const selectShippingPins = createSelector(selectShippingInfo, (shippingInfo) => {
  return Object.keys(shippingInfo.trackingHistoryData)
})
export const selectVendor = createSelector(
  selectShippingInfo,
  (shippingInfo) => shippingInfo.vendor.name
)

export const selectAgentTransferFlag = createSelector(
  selectShippingInfo,
  (shippingInfo) => shippingInfo.agentTransferFlag
)
export const selectHashedPin = createSelector(
  selectShippingInfo,
  (shippingInfo) => shippingInfo.hashedPin
)

export const selectInterceptFlags = createSelector(selectShippingInfo, (shippingInfo) => {
  const { interceptFlag, interceptReason, verifySession } = shippingInfo
  return {
    interceptFlag,
    interceptReason,
    verifySession,
  }
})

export const selectExpressChequeFlags = createSelector(selectShippingInfo, (shippingInfo) => {
  const { expressChequeFlag, expressChequeMOP, expressChequeAmount } = shippingInfo
  return {
    expressChequeFlag,
    expressChequeMOP,
    expressChequeAmount,
  }
})

export const selectSignatureInfo = createSelector(selectShippingInfo, (shippingInfo) => {
  const { signature } = shippingInfo
  const { allowWaiveSignature } = shippingInfo.allowUpdateFlags
  return {
    signature,
    allowWaiveSignature,
  }
})

export const selectAllowFlagsForPreferences = createSelector(selectShippingInfo, (shippingInfo) => {
  const {
    buzzerCode,
    additionalInstructions,
    location,
    allowHFPUChange,
    hasHFPUCompleted,
    hasHFPURequested,
    hasReachedRetailLocation,
  } = shippingInfo.allowUpdateFlags
  return {
    buzzerCode,
    additionalInstructions,
    location,
    allowHFPUChange,
    hasHFPUCompleted,
    hasHFPURequested,
    hasReachedRetailLocation,
  }
})

export const selectHFPUReturnToSender = createSelector(selectShippingInfo, (shippingInfo) => {
  const { hfpuIsReturnedToSender } = shippingInfo
  if (typeof hfpuIsReturnedToSender !== 'boolean') {
    return false
  }
  return hfpuIsReturnedToSender
})

export const selectMyPreferencesInfo = createSelector(selectShippingInfo, (shippingInfo) => {
  const {
    delivery_preference: dropOffLoc,
    buzzer_code: buzzerCode,
    additional_delivery_instructions: delInstr,
  } = shippingInfo
  return {
    dropOffLoc,
    buzzerCode,
    delInstr,
  }
})

export const selectDeliverySummary = createSelector(selectShippingInfo, (shippingInfo) => {
  const {
    signatureRequired,
    adultSignatureRequired: asr,
    delivery_preference: deliveryLocation = '',
    buzzer_code: buzzerCode,
    customerPhoneNumber: phoneNumber,
    additional_delivery_instructions: additionalDeliveryInstructions,
    deliveryPreferencesModified,
    remote_signature_preference,
    chainOfSignature: cos,
    dangerousGoods: dg,
    id: shipmentId,
    hashedPin,
    signature,
  } = shippingInfo
  const mandatorySignature = asr || dg || cos
  let remoteSignaturePreference = remote_signature_preference

  const ptl = remoteSignaturePreference === 'PTL'
  if (typeof remoteSignaturePreference === 'undefined' || !remoteSignaturePreference) {
    if (signature === 'SR') remoteSignaturePreference = 'Regular'
    if (signature === 'waived') remoteSignaturePreference = 'PTL'
  }

  return {
    signatureRequired: signatureRequired && !mandatorySignature && !ptl,
    signatureRequiredForAdditionalInstructions: signatureRequired && signature !== 'waived',
    deliveryLocation,
    buzzerCode,
    phoneNumber,
    additionalDeliveryInstructions,
    remoteSignaturePreference,
    deliveryPreferencesModified,
    mandatorySignature,
    shipmentId,
    hashedPin,
  }
})

export const selectAddressCorrectionInfo = createSelector(selectShippingInfo, (shippingInfo) => {
  const { shipmentPin, shipmentStatus, allowUpdateFlags, hasAddressCorrectionException } =
    shippingInfo
  const { toAddress, requestedAddressCorrection, holdForPickUp } = _.get(
    shippingInfo,
    'packageDetails[0]',
    {
      toAddress: '',
      requestedAddressCorrection: false,
    }
  )
  return {
    allowAddressCorrection: allowUpdateFlags.address && !hasAddressCorrectionException,
    shipmentPin,
    shipmentStatus,
    toAddress,
    addressCorrectionRequested: !!requestedAddressCorrection,
    requestedAddressCorrection,
    holdForPickUp,
  }
})

export const selectEmailNotificationDetails = createSelector(
  selectShippingInfo,
  selectShipping,
  (shippingInfo, shipping) => {
    if (!shippingInfo && !shipping.optedInStatusDetails) {
      return {
        id: '',
        shipmentPin: '',
        hashedPin: '',
        phoneNumber: '',
        optedInForEmail: 0,
        emailAddress: '',
      }
    }
    const { id, shipmentPin, hashedPin, customerPhoneNumber } = shippingInfo
    if (!shipping.optedInStatusDetails || shipping.optedInStatusDetails === 'undefined') {
      return {
        id,
        shipmentPin,
        hashedPin,
        phoneNumber: customerPhoneNumber,
        optedInForEmail: 0,
        emailAddress: '',
      }
    }
    return {
      id,
      shipmentPin,
      hashedPin,
      optedInForEmail: shipping.optedInStatusDetails.data.optedInForEmail,
      emailAddress: shipping.optedInStatusDetails.data.emailAddress,
      phoneNumber: customerPhoneNumber,
    }
  }
)

export const selectSubscribedFlag = createSelector(selectShippingInfo, (shippingInfo) =>
  _.get(shippingInfo, 'isSubscribed', true)
)

export const selectEstimatedDeliveryDate = createSelector(
  selectActivePinDetails,
  (trackingDetails) => trackingDetails.edd
)

export const selectIsTerminalPresent = createSelector(selectShippingInfo, (shippingInfo) =>
  _.get(shippingInfo, 'isTerminalPresent', true)
)

export const selectPackageStatusCode = createSelector(selectActivePinDetails, (trackingDetails) =>
  _.get(trackingDetails, 'statuscode', null)
)

export const selectToCountry = createSelector(selectShippingInfo, (shippingInfo) =>
  _.get(shippingInfo, 'packageDetails.0.toAddress.country', 'CA')
)

export const selectIsShippingToCanadaOrUS = createSelector(
  selectToCountry,
  (country) => country === 'US' || country === 'CA'
)

export const selectIsPackageDelivered = createSelector(
  selectPackageStatusCode,
  (statusCode) => statusCode === 3
)

export const selectPackageStatus = createSelector(selectPackageStatusCode, (statusCode) => {
  const statusCodeMapping = {
    1: 'picked_up',
    2: 'out_for_delivery',
    3: 'delivered',
    4: 'delayed',
    5: 'missed_delivery',
  }
  return statusCodeMapping[statusCode]
})

export const selectDeliveryDateTitleTranslationsKey = createSelector(
  selectIsPackageDelivered,
  (isDelivered) => {
    if (isDelivered) {
      return 'tracking.time_delivery'
    }
    return 'tracking.estimated_time'
  }
)

export const selectTrackingDetails = createSelector(selectShippingInfo, (shippingInfo) =>
  _.get(shippingInfo, 'trackingHistoryData', null)
)

export const selectLeadPin = createSelector(selectTrackingDetails, (td) => _.findKey(td, 'leadPin'))

export const selectSortPackagePinsByStatus = createSelector(
  selectTrackingDetails,
  (trackingHistoryData) => {
    const statusWeight = {
      0: 5,
      4: 4,
      2: 3,
      1: 2,
      3: 1,
    }
    const trackingHistoryListWithPin = Object.keys(trackingHistoryData).map((pin) => ({
      ...trackingHistoryData[pin],
      pin,
    }))
    const listWithWeight = trackingHistoryListWithPin.map((p) => {
      const weight = p.leadPin ? statusWeight[0] : statusWeight[p.statuscode]
      return { ...p, weight }
    })
    const sortedPackagePinsBySatus = listWithWeight
      .sort((a, b) => b.weight - a.weight)
      .map((p) => p.pin)

    return sortedPackagePinsBySatus
  }
)

export const selectPackageDetails = createSelector(
  selectShippingInfo,
  selectSortPackagePinsByStatus,
  (shippingInfo, packagePinList) => {
    const { packageDetails } = shippingInfo
    return Array.isArray(packageDetails) && packageDetails.length > 0
      ? packagePinList.map((pin) => {
          const pd = _.find(packageDetails, ['packagepin', pin])
          return {
            packagepin: pd.packagepin,
            packageWeight: `${pd.packageWeight} ${pd.packageWeightUnit}`,
            references: pd.references.filter((ref) => ref),
            service: pd.service,
            isLeadPin: shippingInfo.trackingHistoryData[pd.packagepin].leadPin,
            status: shippingInfo.trackingHistoryData[pd.packagepin].status,
            statusCode: shippingInfo.trackingHistoryData[pd.packagepin].statuscode,
            isMissedDelivery: shippingInfo.trackingHistoryData[pd.packagepin].missedDeliveryFlag,
            shipmentDate: pd.shipmentDate,
            fromAddress: pd.fromAddress,
            toAddress: pd.toAddress,
          }
        })
      : []
  }
)

export const selectTransitHistory = createSelector(selectActivePinDetails, (trackingDetails) => {
  return trackingDetails.transitHistory.map((th) => ({
    scanDesc: th.description,
    terminal: th.provinceState ? `${th.name}, ${th.provinceState}` : th.name,
    scanDateTime: th.dateTime,
  }))
})

export const selectShipmentId = createSelector(
  selectShippingInfo,
  (shippingInfo) => shippingInfo.id
)

export const selectSelectedPin = createSelector(selectShipping, (shipping) => shipping.selectedPin)

export const selectIsLeadPinDelivered = createSelector(
  selectLeadPin,
  selectTrackingDetails,
  (leadPin, trackingDetails) => {
    const statusCode = _.get(trackingDetails, `${leadPin}.statuscode`, null)
    return statusCode === 3
  }
)
