import React from 'react'
import Header from 'components/Header'
import Footer from 'components/Footer'
import { useSearchParams } from 'react-router-dom'
import EmailVerificationCard from 'components/EmailVerificationCard'

export default function Unsubscribe() {
  const [searchParams] = useSearchParams()

  return (
    <div
      style={{
        fontFamily: 'Open Sans',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Header app="unsubscribe" disableNavigation />
      <EmailVerificationCard token={searchParams.get('token')} />
      <Footer />
    </div>
  )
}
